/*
* @NOTE: Prepend a `~` to css file paths that are in your node_modules
*        See https://github.com/webpack-contrib/sass-loader#imports
*/

@font-face {
font-family: "Open Sans";
src: url("./assets/fonts/OpenSans-VariableFont_wdth\,wght.ttf");
}

body {
position: relative;
color: #fff;
height: 100vh;
background: #E8EEF2;
font-family: "Open Sans", sans-serif;
overflow: hidden;
display: flex;
justify-content: center;
align-items: center;
font-size: 1rem;
}

div.mycontainer {
max-width: 100% !important;
}

.Hello img {
border: 3px solid #fff;
border-radius: 50%;
margin-bottom: 3rem;
box-shadow: 0px 4px 14px -3px #333,
  0px 4px 14px -2px #333;
}

@media screen and (min-width:0px) and (max-width:425px){

.Hello img {
  width: 10rem;
  height: 10rem;
  margin-top: 2rem;
}

select {
  width: 18rem !important;
  margin: 0 2rem;
}

button.active {
  width: 18rem;
  margin: 0 1rem;
}    

.hstack {
  width: 18rem;
  margin: 0 1rem;
}

}

@media screen and (min-width:426px) and (max-width:1440px){

  .Hello img {
    width: 10rem;
    height: 10rem;
    margin-top: 2rem;
  }
  
  select {
    width: 18rem !important;
    margin: 0 2rem;
  }
  
  button.active {
    width: 18rem;
    margin: 0 1rem;
  }    
  
  .hstack {
    width: 18rem;
    margin-left: 2rem;
  }

  }

button {
background-color: #fff;
padding: 10px 20px;
border-radius: 10px;
border: 1px solid #343633;
appearance: none;
font-size: 1rem;
box-shadow: 0px 4px 14px -3px #333,
  0px 4px 14px -2px #333;
transition: all ease-in 0.1s;
cursor: pointer;
opacity: 0.9;
color: #343633;
}

button.active {
background-color: #2558D4;
padding: 10px 20px;
border-radius: 10px;
border: 1px solid #fff;
appearance: none;
font-size: 1rem;
box-shadow: 0px 4px 14px -3px #333,
  0px 4px 14px -2px #333;
transition: all ease-in 0.1s;
cursor: pointer;
opacity: 0.9;
color: #fff;
}

button:hover {
transform: scale(1.05);
opacity: 1;
}

li {
list-style: none;
}

a {
text-decoration: none;
height: fit-content;
width: fit-content;
margin: 0.1rem;
}

a:hover {
opacity: 1;
text-decoration: none;
}

.Hello {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

select{
background: white !important;
margin: 0 1rem;
font-size: 1rem !important;
text-align: center;
padding: 0px !important;
}

select option{
text-align: center;
font-size: 1rem !important;
margin: 0;
}

.playerCard{
font-size: 1rem;
display:flex;
flex-direction:row;
justify-content: center;
align-items: center;
color:#fff;
margin: 0.5rem 0;
}

.playerCard span{
display:flex;
flex-direction:row;
justify-content: center;
align-items: center;
width: 15px;
height: 15px;
}

.playerCard p{
margin-bottom: 0;
}

p {
font-size: 1rem;
overflow-wrap: break-word;
color: #343633;
}

.page-item {
padding: 0;
}

.offcanvas {
  background:#E8EEF2;
}

.offcanvas-title {
color: #343633;
}

.vstack {
display: flex;
justify-content: center;
align-items: center;
}